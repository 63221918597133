import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"
import {firestore, functions} from "@/firebase/index.js"
import {
  collection,
  setDoc,
  updateDoc,
  doc,
  serverTimestamp,
  where,
  query,
  getDocs,
} from "firebase/firestore"
import {httpsCallable} from "firebase/functions"
import {useShopStore} from "./shop"
import {useUserStore} from "./user"

export const useShopGiftCardsStore = defineStore("shopGiftCards", () => {
  // stores
  const shopStore = useShopStore()
  const userStore = useUserStore()

  // state
  const promoCodes = ref([])
  const status = ref("init")
  const unsubscribe = ref(null)

  // getters
  const shopGiftCardsLoaded = computed(() => status.value == "loaded")

  // actions
  async function bind() {
    const shopId = shopStore.shopId
    const collectionRef = collection(firestore, `shops/${shopId}/promoCodes`)
    const q = query(collectionRef, where("kind", "==", "giftCard"))
    return await this.attach("promoCodes", q)
  }

  async function get() {
    status.value = "loading"
    const shopId = shopStore.shopId
    const collectionRef = collection(firestore, `shops/${shopId}/promoCodes`)
    const q = query(collectionRef, where("kind", "==", "giftCard"))
    const querySnapshot = await getDocs(q)
    this.promoCodes = querySnapshot.docs.map((doc) => ({...doc.data(), id: doc.id}))
    this.status = "loaded"
  }

  async function unbind() {
    return await this.detach()
  }

  async function add(args) {
    console.log("add", args)
    const shopId = shopStore.shopId
    const docRef = doc(collection(firestore, `shops/${shopId}/promoCodes`))

    // if you edit fields, consider editing functions/actions/misc/processGiftCard.fn/giftCardCode

    const promoCodeData = {
      kind: args.kind,
      code: args.code,
      count: args.kind == "giftCard" ? 1 : args.count,
      unit: args.kind == "giftCard" ? "value" : args.unit,
      value: args.value,
      valueRemaining: args.value,
      allowOffline: args.allowOffline,
      allowOnline: args.allowOnline,
      allowCredit: args.allowCredit,
      from: args.from,
      to: args.to,
      daysValidFromPurchase: args.daysValidFromPurchase,
      expirationKind: args.expirationKind,
      tags: args.tags,
      note: args.note,
      usage: [],
      version: args.version || 1,
      customer: args.customer,
      meta: {
        created: serverTimestamp(),
        updated: null,
        deleted: null,
      },
    }

    if (args.kind == "giftCard") {
      promoCodeData.giftCard = {
        forGiftCardProductId: args.giftCard?.forGiftCardProductId
          ? args.giftCard.forGiftCardProductId
          : null,
        soldWithOrder: {
          id: null,
          number: null,
        },
        kind: args.giftCard?.kind ? args.giftCard.kind : null,
      }
    } else if (args.kind == "discount") {
      promoCodeData.usage = {
        orders: [],
      }
    }

    // version 2 registers initial top up as usage
    // except for burgerman cards imported for paysy - they have initial top up, but don't mention version 2
    if (args.version === 2) {
      const usedInRef = doc(collection(firestore, `shops/${shopId}/promoCodes/${docRef.id}/usedIn`))

      const usedInData = {
        usedAmount: args.value,
        usedCurrency: "CZK",
        usedKind: "manual",
        direction: "add",
        // note: args.note,
        validatedBy: {
          userId: userStore.userId,
        },
        meta: {
          created: serverTimestamp(),
          updated: false,
          deleted: false,
        },
      }

      promoCodeData.usage.push(usedInRef)

      await setDoc(usedInRef, usedInData)
    }

    await setDoc(docRef, promoCodeData)

    // recalculate stats
    if (args.version !== 2) {
      try {
        const rs = httpsCallable(functions, "calls-commerce-admin-recalculatePromoCodeStats")
        await rs({shopId})
      } catch (error) {
        console.error(error)
      }
    }

    return docRef
  }

  async function update(args) {
    const shopId = shopStore.shopId
    const promoCodeId = args.id
    const docRef = doc(firestore, `shops/${shopId}/promoCodes/${promoCodeId}`)

    // if you edit fields, consider editing functions/actions/misc/processGiftCard.fn/giftCardCode
    const data = {
      kind: args.kind,
      code: args.code,
      count: args.kind == "giftCard" ? 1 : args.count,
      unit: args.kind == "giftCard" ? "value" : args.unit,
      value: args.value,
      allowOffline: args.allowOffline,
      allowOnline: args.allowOnline,
      allowCredit: args.allowCredit,
      expirationKind: args.expirationKind,
      from: args.from,
      to: args.to,
      daysValidFromPurchase: args.daysValidFromPurchase,
      tags: args.tags,
      note: args.note,
      "meta.updated": serverTimestamp(),
    }

    if (args.kind == "giftCard") {
      data.giftCard = {
        forGiftCardProductId: args.giftCard.forGiftCardProductId
          ? args.giftCard.forGiftCardProductId
          : null,
        soldWithOrder: {
          id: null,
          number: null,
        },
        kind: args.giftCard.kind ? args.giftCard.kind : null,
      }
    } else if (args.kind == "discount") {
      data.usage = {
        orders: [],
      }
    }

    return await updateDoc(docRef, data)
  }

  return {
    // state
    promoCodes,
    status,
    unsubscribe,

    // getters
    shopGiftCardsLoaded,

    // actions
    bind,
    get,
    unbind,
    add,
    update,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useShopGiftCardsStore, import.meta.hot))
}
