import {ref, computed} from "vue"
import {defineStore, acceptHMRUpdate} from "pinia"

export const useAppStore = defineStore("app", () => {
  // state
  const app = ref({
    orgId: null, // unused
    spaceId: null,
    environmentId: null,
    darkMode: localStorage.uiDarkMode || "system", // light | dark | system
    floatingMenu: {},
  })

  const modal = ref({
    visible: false,
    type: null,
    title: "",
    message: "",
    buttons: [],
    payload: null,
  })

  // getters
  const orgId = computed(() => app.value.orgId)
  const spaceId = computed(() => app.value.spaceId)
  const environmentId = computed(() => app.value.environmentId)

  // actions
  function modalShow(args) {
    modal.value.visible = true
    modal.value.type = args.type
    modal.value.message = args.message
    modal.value.payload = args.payload
    modal.value.buttons.splice(0)
    args.buttons.forEach((button) => {
      modal.value.buttons.push(button)
    })
  }

  function modalHide() {
    modal.value.visible = false
  }

  function modalExecute(button) {
    button.action(modal.value.payload)
  }

  return {
    // state
    app,
    modal,

    // getters
    orgId,
    spaceId,
    environmentId,

    // actions
    modalShow,
    modalHide,
    modalExecute,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useAppStore, import.meta.hot))
}
